<template>
  <el-container class="home">
    <el-header
      style="
        background-color: #167bd8;
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div style="font-size: 20px">后台管理系统</div>
      <div class="header-right">
        <el-dropdown placement="bottom-start">
          <span class="el-dropdown-link">
            <img class="userimg" src="../assets//images/avatar.jpg" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item>个人中心</el-dropdown-item> -->
            <el-dropdown-item @click.native="loginOut">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="userinfo">
          <div class="usernames">欢迎您，admin</div>
          <div>{{ date }}</div>
        </div>
      </div>
    </el-header>
    <el-container>
      <el-aside width="auto" style="border-right: 1px solid #e6e6e6">
        <menu-bar></menu-bar>
      </el-aside>
      <el-container>
        <el-main style="padding:0px;overflow-y:hidden">
          <i
            class="arrow-icon"
            :class="[isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold']"
            @click="iconClick"
          ></i>
          <tabs style="margin-bottom:20px"></tabs>
          <div class="main_view">
            <router-view></router-view>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import MenuBar from "../components/MenuBar";
import tabs from "../components/tabs";
import { removeToken, removeUserId, cleanStorage } from "../utils/auth";
import { mapState } from "vuex";
var $vueIndex;
export default {
  components: {
    MenuBar,
    tabs
  },
  computed: {
    ...mapState({
      isCollapse: state => state.MenuStore.isCollapse
    })
  },
  data() {
    return {
      date: ""
    };
  },
  mounted() {
    $vueIndex = this;
    this.showTime();
    setInterval(function() {
      $vueIndex.showTime();
    }, 1000);
  },
  methods: {
    iconClick() {
      this.$store.commit("setOpenOrClose");
    },
    loginOut() {
      removeToken();
      removeUserId();
      cleanStorage();
      window.location.href = "/login";
    },
    showTime() {
      var week = new Array(
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      );
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hour = date.getHours();
      var minutes = date.getMinutes();
      var second = date.getSeconds();
      this.date =
        year +
        "." +
        (month < 10 ? "0" + month : month) +
        "." +
        day +
        "" +
        " " +
        hour +
        ":" +
        minutes +
        ":" +
        (second < 10 ? "0" + second : second) +
        " " +
        (week[date.getDay()] || "");
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  overflow-y: hidden;
}

.header-right {
  display: flex;
}

.userimg {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.userinfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10px;
}

.usernames {
  font-weight: 600;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.arrow-icon {
  float: left;
  background: #eaedf1;
  border: 1px solid transparent;
  font-size: 23px;
  height: 39px;
  line-height: 39px !important;
  width: 40px;
  text-align: center;
}
.el-main ::v-deep .el-tabs__header {
  position: static !important;
}
.main_view{
  height: calc(100vh - 150px);
  // border: 1px solid red;
}
</style>
